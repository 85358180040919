import React, { useEffect } from "react";

const AboutSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">About Me.</h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">
          Hai, nama saya <span>Maha Abdillah</span> Saya adalah orang yang senang belajar dan menerima kritik yang membangun.
Kreatif, Disiplin (tepat waktu dan berkomitmen tinggi dalam menyelesaikan
pekerjaan). Insyallah dalam urusan pekerjaan saya sangat profesional, teliti dan
bertanggung jawab. Saya orangnya supel dan mudah bersosialisasi. Saya tidak merokok dan minum.
        </p>
        <div className="about-detail-main">
          <p className="about-detail">Name</p>
          <p className="about-detail-info">Maha Abdillah</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Address</p>
          <p className="about-detail-info">Kota Pontianak, Kalbar</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Whatsapp</p>
          <p className="about-detail-info">08981992345</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Email</p>
          <p className="about-detail-info">maha4bdillah@gmail.com</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Experience</p>
          <p className="about-detail-info">7+ years</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Education</p>
          <p className="about-detail-info">S1 Rekayasa Sistem Komputer</p>
        </div>

      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
    </>
  );
};
export default AboutSection;
