import React from "react";
import BasicPlanSvg from "../assets/images/svg/basic-plain.svg";
import StandardPlanSvg from "../assets/images/svg/strandard-plan.svg";
import GoldPlanSvg from "../assets/images/svg/gold-plan.svg";
import ColanIcon from "../assets/images/svg/colan-icon.svg";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

const Pricing = () => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    dots: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* <!-- ====================================== Section Pricing ===================================== --> */}
      <section className="Pricing-section" id="pricing">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Pricing.
          </h2>
          <div className="line"></div>
        </div>
        <div className="pricing-section-main zoom_in">
          <div className="basic-plain-box-main">
            <div className="basic-plain-box">
              <img src={BasicPlanSvg} alt="basic-plain" />
              <p className="basic-plain-text">Jasa Download</p>
            </div>
            <div className="basic-plain-box-sub">
                <span>Start From</span>
              <p className="mon">
                5<sup>k</sup>
              </p>
            </div>
          </div>
          <div className="development-box">
            <div>
              <h3 className="pricing-plaines-name">Freepik</h3>
              <h3 className="pricing-plaines-name">Envato Element</h3>
              <h3 className="pricing-plaines-name">pngTree</h3>
              <h3 className="pricing-plaines-name">Pikbest</h3>
              <h3 className="pricing-plaines-name">Lovepik, Etc...</h3>
            </div>
            <div className="choose-plain-btn-main">
              <div className="wrapper">
                <a className="btn-hover" href="#">
                  Pilih Jasa
                </a>
              </div>
              <p className="taxes">*harga sewaktu waktu bisa berubah</p>
            </div>
          </div>
        </div>
        <div className="pricing-section-main zoom_in">
          <div className="basic-plain-box-main">
            <div className="basic-plain-box basic-plain-box2">
              <img src={StandardPlanSvg} alt="strandard-plan" />
              <p className="basic-plain-text">Install Ulang Laptop</p>
            </div>
            <div className="basic-plain-box-sub">
                <span>Start From</span>
              <p className="mon">

                40<sup>k</sup>

              </p>
            </div>
          </div>
          <div className="development-box">
            <div>
              <h3 className="pricing-plaines-name">Windows 7</h3>
              <h3 className="pricing-plaines-name">Windows 8</h3>
              <h3 className="pricing-plaines-name">Windows 10</h3>
              <h3 className="pricing-plaines-name">Windows 11</h3>
              <h3 className="pricing-plaines-name">Aplikasi terlengkap</h3>
            </div>
            <div className="choose-plain-btn-main">
              <div className="wrapper">
                <a className="btn-hover" href="#">
                  Pilih Jasa
                </a>
              </div>
              <p className="taxes">*harga sewaktu waktu bisa berubah</p>
            </div>
          </div>
        </div>
        <div className="pricing-section-main mb-0 zoom_in">
          <div className="basic-plain-box-main">
            <div className="basic-plain-box basic-plain-box3">
              <img src={GoldPlanSvg} alt="gold-plan" />
              <p className="basic-plain-text">Create Website</p>
            </div>
            <div className="basic-plain-box-sub">
                <span>Start From</span>
              <p className="mon">
                500<sup>k</sup>

              </p>
            </div>
          </div>
          <div className="development-box">
            <div>
              <h3 className="pricing-plaines-name">Web Development</h3>
              <h3 className="pricing-plaines-name">Mobile Development</h3>
              <h3 className="pricing-plaines-name">Advertising</h3>
              <h3 className="pricing-plaines-name">Graphic Design</h3>
              <h3 className="pricing-plaines-name">Project Management</h3>
            </div>
            <div className="choose-plain-btn-main">
              <div className="wrapper">
                <a className="btn-hover" href="#">
                  Pilih Jasa
                </a>
              </div>
              <p className="taxes">*harga menyesuaikan project yang diberikan</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Pricing End ===================================== --> */}
      {/* <!-- ====================================== Section Testimonials ===================================== --> */}
      <section className="testimonials-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Testimonials.
          </h2>
          <div className="line"></div>
        </div>
        <p className="testament fade_up">
Berikut adalah beberapa kata-kata mereka yang telah menggunakan jasa kami.
        </p>

        <Slider className="Testimonials" {...settings}>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
Langganan jasa download file berlisense sm abang ini, murah dan legal!
            </p>
            <p className="client-name">Syarief Vernanda</p>
            <p className="kello">Designer</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
Pas skripsi aku ngalamin problem dilaptop yang lemot dan suka panas banget, setelah aku service ke abang laptop saya jadi normal kembali dan skripsi aku lancar bang. makaseh
            </p>
            <p className="client-name">Citra</p>
            <p className="kello">Mahasiswi</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
katalog penjualan rumah sy jd lebih rapi dan calon pembeli jadi lebih enak melihat daftar rumah.
            </p>
            <p className="client-name">Novia Fadhilah</p>
            <p className="kello">Toko Online Properti</p>
          </div>
          <div>
            <div className="colan-icon-img-main">
              <img src={ColanIcon} alt="colan-icon" />
            </div>
            <p className="avix">
langgananku sejak dulu, install laptop ntah udah berape laptop saye bawa ke abang.
            </p>
            <p className="client-name">Erwin</p>
            <p className="kello">Mahasiswa</p>
          </div>
        </Slider>
      </section>
      {/* <!-- ====================================== Section Testimonials ===================================== --> */}
    </>
  );
};
export default Pricing;
